<template>
  <article class="faq-column">
    <h3 id="renewable-energy-faq-heading">Frequently asked questions about renewable energy & sustainability</h3>
    <accordion header-id="renewable-energy-faq-heading">
      <accordion-item title="What is a solar canopy?">
        <p>A solar canopy is a covering structure that holds solar panels and offers protection from the weather.</p>
      </accordion-item>
      <accordion-item title="How fast is solar EV charging?">
        <p>
          Today, Electrify America’s 100% solar-powered chargers offer Level 2 speeds, charging capable vehicles at a
          maximum rate of 3.3 kW.
        </p>
        <p>
          Charging stations equipped with solar canopies integrate with the local electrical grid (and, in some
          locations, on-site energy storage) to help us deliver DC fast charging.
        </p>
        <p>
          You can also find each charger’s maximum rate by checking the
          <a class="link" href="/mobile-app">Electrify America app</a>
          or the charger’s screen.
        </p>
      </accordion-item>
      <accordion-item title="Where are Electrify America’s solar chargers located?">
        <p>
          Currently, all Electrify America charging stations with solar features (both the fully solar stations and our
          on-site solar canopy) are located in California.
        </p>
      </accordion-item>
      <accordion-item title="How can I learn more about solar energy in general?">
        <p>
          You can learn more about solar energy and photovoltaics for your home and other uses by visiting the
          <a rel="noopener" target="_blank" class="link" href="https://www.energy.gov/eere/solar/how-does-solar-work"
            >U.S. Department of Energy’s website.</a
          >
        </p>
      </accordion-item>
      <accordion-item title="How can I learn more about the environmental impact of energy from utilities in my area?">
        <p>
          The electrical grid in each region of the U.S. puts out differing emission levels. This often varies by time
          of day and weather conditions, which may influence renewable energy generation. To see emissions in your ZIP
          code, visit the
          <a rel="noopener" target="_blank" class="link" href="https://www.epa.gov/egrid/power-profiler#/"
            >U.S. Environmental Protection Agency’s Power Profiler.</a
          >
        </p>
      </accordion-item>
    </accordion>
  </article>
</template>

<script>
import Accordion from './Accordion/Accordion.vue';
import AccordionItem from './Accordion/AccordionItem.vue';

export default {
  name: 'renewable-energy-faq',
  components: {
    Accordion,
    AccordionItem,
  },
};
</script>
