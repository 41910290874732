<template>
  <div class="renewable-energy" :class="{ mobile: isPhone }">
    <Hero heading="Renewable Energy &amp; Sustainability" :bgColor="false">
      <template #image>
        <img
          :src="
            !isPhone
              ? require('../assets/images/Renewable-Energy/hero.webp')
              : require('../assets/images/Renewable-Energy/hero-mobile.webp')
          "
          alt="Two EVs charging under an Electrify America canopy"
        />
      </template>
      <h3>Now backed by 100% renewable energy</h3>
      <p>
        One of Electrify America’s primary goals is to support Zero Emission Vehicle adoption in the United States, but
        we’re not stopping there. Electrify America is committed to a net zero carbon footprint for all energy delivered
        to drivers. Today when you charge with us, the energy delivered from our network is backed by 100% renewable
        energy via renewable energy certificates.
      </p>
    </Hero>

    <Card headlineLevel="h2" heading="Our focus on additionality" headlineDisplayClass="headline3" bgColor>
      <template #image>
        <picture>
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/Renewable-Energy/electrify-america-solar-glow-1.webp"
            alt="An aerial view of solar panels on a solar farm"
          />
        </picture>
      </template>
      <h4>Electrify America Solar Glow&trade; 1: Our First Solar Farm</h4>
      <p>
        Our first solar farm in Southern California is now operational and creating new renewable energy. Electrify
        America Solar Glow™ 1 is an important milestone in our commitment to reduce our energy carbon footprint.
      </p>
      <a class="button-link" href="https://media.electrifyamerica.com/en-us/releases/226" rel="noopener" target="_blank"
        >Learn More<arrow class="arrow"></arrow
      ></a>
    </Card>

    <div class="l-one--has-bg">
      <section class="video l-one__col">
        <iframe
          src="https://www.youtube.com/embed/JdDOtjTo7fU"
          title="Additionality"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </section>
    </div>

    <Card
      headlineLevel="h2"
      heading="Types of solar charging stations and features"
      :isReverse="true"
      headlineDisplayClass="headline3"
      class="no-bottom-padding"
    >
      <template #image>
        <picture>
          <source
            media="(min-width:750px)"
            srcset="@/assets/images/Renewable-Energy/solar-powered-charging-station.png"
          />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/Renewable-Energy/solar-powered-charging-station.png"
            alt="An Electrify America solar L2 station with chargers for two vehicles."
          />
        </picture>
      </template>
      <h4>100% solar-powered Level 2 stations</h4>
      <p>
        So far, Electrify America has invested $2 million in 30 solar-powered electric vehicle (EV) charging stations in
        rural California. These resilient Level 2 (L2) stations aren’t tied to the electrical grid, and they provide
        drivers in rural areas access to EV charging via renewable resources.
      </p>
      <p>
        Each station includes a sun-tracking solar array that can charge two vehicles at the same time—regardless of
        weather or time of day—thanks to co-located energy storage. Each charger offers the ability to charge capable
        vehicles at a maximum rate of 3.5 kW.
      </p>
    </Card>

    <Card headlineLevel="h2" :isReverse="isPhone" heading="On-site solar canopies" headlineDisplayClass="headline4">
      <template #image>
        <picture>
          <source media="(min-width:750px)" srcset="@/assets/images/Renewable-Energy/charging-stations-night.png" />
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/Renewable-Energy/charging-stations-night-mobile.png"
            alt="An Electrify America solar L2 station with chargers for two vehicles."
          />
        </picture>
      </template>
      <div class="content flex-column">
        <p>
          At some locations, such as our Baker, California station, a large canopy of solar panels provides shade from
          the elements while you charge your vehicle using one of the
          <router-link class="link" :to="{ name: 'glossary-en', query: { load: 6 } }">DC fast chargers</router-link>
          on-site.
        </p>
        <p>
          These charging stations equipped with solar canopies integrate with the local electrical grid, and some are
          equipped with on-site energy storage, to help us deliver DC fast charging. The solar canopy can also deliver
          energy back to the grid.
        </p>
      </div>
    </Card>

    <Card
      headlineLevel="h2"
      heading="Building a grid-friendly charging network"
      isReverse
      headlineDisplayClass="headline3"
      bgColor
    >
      <template #image>
        <picture>
          <img
            loading="lazy"
            class="image"
            src="@/assets/images/Renewable-Energy/charger-installation.png"
            alt="An Electrify America charging station with battery energy storage system."
          />
        </picture>
      </template>
      <p>
        Charging several electric vehicles at the same station requires lots of power. We’ve installed battery storage
        systems in some locations to supplement the grid at peak times, when electricity use is usually at its highest.
        To date, Electrify America has installed 150+ Battery Energy Storage Systems (BESS).
      </p>
      <a class="button-link" rel="noopener" target="_blank" href="http://media.electrifyamerica.com/"
        >CHECK OUR MEDIA SITE FOR THE LATEST ANNOUNCEMENTS ABOUT BATTERY STORAGE <Arrow class="arrow"
      /></a>
    </Card>
    <RenewableEnergyFAQ />
  </div>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow.svg';
import RenewableEnergyFAQ from '@/components/RenewableEnergyFAQ.vue';
import Card from '@/components/Card/Card.vue';
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'renewable-energy',
  components: {
    Arrow,
    RenewableEnergyFAQ,
    Card,
    Hero,
  },
  metaInfo: {
    title: 'Renewable Energy & Sustainability | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Electrify America invests in renewable energy solutions like solar EV chargers in our effort to lower our energy-related emissions overall and improve your EV charging experience.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/renewable-energy/' }],
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(800);
    },
  },
};
</script>

<style scoped lang="scss">
.renewable-energy {
  .l-one--has-bg {
    padding-bottom: 100px;
  }
  .card.no-bottom-padding {
    @media (max-width: 750px) {
      padding-bottom: 0;
    }
  }

  h4 {
    font-weight: 400;
  }

  .arrow {
    margin-left: 8px;
    path {
      fill: $c-primary;
    }
  }

  a.button-link {
    display: block;
    margin-bottom: 32px;
    @media (min-width: 800px) {
      margin-bottom: 0;
    }
  }

  .video {
    display: flex;
    justify-content: center;
    iframe {
      width: 100%;
      height: 50.625vw;
      max-height: 442px;
      max-width: 785px;
    }
    @media (min-width: 800px) {
      padding-top: 115px;
    }
  }
}
</style>
